import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Education Fund for Grace Derdock" />
    <section>
      <h1 className="text-center font-bold text-3xl lg:text-4xl mb-2 mt-12">
        Education Fund for Grace Derdock
      </h1>
      <p className="text-center mb-6">
        In lieu of flowers, please consider contributing to Grace's fund for
        future education.
      </p>
    </section>

    <div style={{ marginBottom: `1.45rem` }}>
      <StaticImage src="../images/grace.jpeg" alt="Grace Derdock" />
    </div>
    <section>
      <h1 className="text-center mb-6 mt-12 font-bold uppercase">
        Donation Options
      </h1>
      <div className="mb-8 text-center">
        <h2 className="font-bold">Maryland 529 Direct</h2>
        <p>
          Please note - I have removed the 529 Direct option as there's no way
          to indicate who has contributed and the family would like to send a
          personal thank you to all who have. Thanks for your generous
          contributions so far!
        </p>
      </div>
      <div className="mb-8 text-center">
        <h2 className="font-bold">PayPal</h2>
        <p>
          Any donations sent via PayPal will be transferred to Grace's Maryland
          529 account.{" "}
        </p>
        <a href="https://paypal.me/gracederdock" className="mt-2">
          Donate via PayPal
        </a>
      </div>
      <div className="mb-8 text-center">
        <h2 className="font-bold">Venmo</h2>
        <p>
          Any donations sent via Venmo will be transferred to Grace's Maryland
          529 account.{" "}
          <em>
            Venmo may ask to verify the last 4 digits of the associated phone
            number, which are <b>0308</b>
          </em>
          .
        </p>
        <a href="https://account.venmo.com/u/James-Derdock" className="mt-2">
          Donate via Venmo
        </a>
      </div>
    </section>
  </Layout>
)

export default IndexPage
